<template>
  <div>
    <van-nav-bar
      title="入会申请"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
      v-show="!pleshow"
    />

    <div style="padding-top:46px;">
      <van-row v-show="!pleshow">
        <van-col span="12" class="lstk">门店名称</van-col>
        <van-col span="12" class="rstk">
          <!-- <div class="dd" style="color: #ee0a24;">待审核</div> -->
          <div class="ddtk" style="color: #4fc08d">{{garageName}}</div>
        </van-col>
      </van-row>

      <van-cell-group v-show="!pleshow">
        <van-field
          v-model="addForm.realName"
          required
          clearable
          label="您的姓名"
          placeholder="请输入您的姓名"
        />
        <van-field v-model="addForm.phone" required clearable label="手机号码" placeholder="请输入手机号码" />
        <van-field
          v-model="addForm.carNo"
          required
          clearable
          label="车牌号码"
          placeholder="请输入车牌号码"
          readonly
          @click="getple"
        />
      </van-cell-group>
      <div class="sumit">
        <van-button type="primary" size="normal" @click="submitForm">提交</van-button>
      </div>

      <carPlate
        ref="ple"
        v-show="pleshow"
        :plate_number="addForm.carNo"
        @getPlate="getPlate"
        style="padding-top: 2rem;"
      ></carPlate>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
import carPlate from "../../components/carplate.vue";
function getSearchString(key, Url) {
  var str = Url;
  str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
  // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
  var arr = str.split("&");
  var obj = new Object();
  // 将每一个数组元素以=分隔并赋给obj对象
  for (var i = 0; i < arr.length; i++) {
    var tmp_arr = arr[i].split("=");
    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
  }
  return obj[key];
}
export default {
  components: {
    carPlate
  },
  data() {
    return {
      list: [],
      value: "",
      pleshow: false,
      gid: "",
      garageName: "",
      customerId: "",
      carNo: localStorage.getItem("carNo"),
      addForm: {}
    };
  },
  methods: {
    onLoad() {
    },

    submitForm() {
      if (!this.addForm.realName) {
        return this.$toast("请输入您的姓名");
      }
      if (!this.addForm.phone) {
        return this.$toast("请输入手机号");
      }
      if (!this.addForm.carNo) {
        return this.$toast("请输入车牌号");
      }
      let data = {
        carNo: this.addForm.carNo,
        customerId: this.customerId,
        garageName: this.garageName,
        gid: this.gid,
        name: this.addForm.realName,
        phone:this.addForm.phone
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "提交中..."
      });
      console.log(data);
      userwei
        .recommended(data)
        .then(res => {
          loading.clear();
          if (res.code === 200) {
            this.$toast.success("提交成功");
            setTimeout(() => {
              // this.$router.go(-1);
            }, 1500);
          } else {
            this.$toast(res.message);
          }
        })
        .catch(() => loading.clear());
    },

    getple() {
      //车牌号
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    getPlate(val) {
      this.addForm.carNo = val;
      this.pleshow = false;
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    var search = window.location.search;
    this.gid = getSearchString("gid", search); //结果：18
    this.garageName = getSearchString("garageName", search); //结果：18
    this.customerId = getSearchString("customerId", search); //结果：18
    console.log(getSearchString("gid", search));
    console.log(getSearchString("garageName", search));
    console.log(getSearchString("customerId", search));
    this.onLoad();
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
div {
  color: #323233;
}
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.sumit {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.van-button {
  width: 100%;
}
.van-row {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebedf0;
  overflow: hidden;
}
.lstk {
  padding-left: 20px;
  font-size: 14px;
}
.ls2 {
  padding-left: 40px;
}
.rstk {
  height: 45px;
  overflow: hidden;
  text-align: right;
  padding-right: 20px;
  font-size: 14px;
}
.ddtk {
  // display: inline-block;
  float: right;
}
</style>
